import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {ApiResponseV2} from "../interface/api-response.interface";
import {Faq, Settings} from "../interface/settings.interface";

const API_URL: string = environment.api_url;

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public _settingsData?: Settings;
  public _faqsData: Faq[] = [];

  _settings: BehaviorSubject<Settings | undefined>;
  _faqs: BehaviorSubject<Faq[]>;

  constructor(private _http: HttpClient) {
    this._settings = new BehaviorSubject(this._settingsData);
    this._faqs = new BehaviorSubject(this._faqsData);
  }
  changeSettingsData(settings: Settings) {
    this._settings.next(settings);
  }
  changeFaqsData(faqs: Faq[]) {
    this._faqs.next(faqs);
  }
  getSettings = (): Observable<ApiResponseV2> => this._http.post<ApiResponseV2>(`${API_URL}/settings`, {});
  getFaqs = (): Observable<ApiResponseV2> => this._http.get<ApiResponseV2>(`${API_URL}/faqs`, {});

}
