import {AfterViewChecked, Component, OnInit, Renderer2} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgwWowService } from 'ngx-wow';
import { LoaderService } from './service/loader.service';
import { LocalSettingsService } from './service/local-settings.service';
import {CartService} from "./service/cart.service";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

declare const fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  showLoader: boolean = false;
  userLang: string = 'ar';

  constructor(private renderer: Renderer2, private wowService: NgwWowService, private _loader: LoaderService, private _translate: TranslateService, public _localSettings: LocalSettingsService, private _cart: CartService, private router: Router) {
    this.wowService.init();
    this._cart.storageToCart()
    var storedLang: string = _localSettings.getLanguage();
    if (storedLang !== "") {
      this.userLang = storedLang;
    } else {
      this._localSettings.setLanguage(this.userLang);
    }
    _translate.use(this.userLang);
    if (typeof window !== 'undefined') {
      if(this._localSettings.getLanguage() == 'en') {
        this.renderer.removeClass(document.body, 'arabic');
        this.renderer.addClass(document.body, 'english');
      } else {
        this.renderer.addClass(document.body, 'arabic');
        this.renderer.removeClass(document.body, 'english');
      }
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      //  gtag('event', 'page_view', {
      //     page_path: event.urlAfterRedirects
      //  })
       fbq('track', 'PageView');
      /** END */
    })
  }

  ngOnInit(): void {
    this._loader.event.subscribe(value => {
      this.showLoader = value;
    });
  }
}
