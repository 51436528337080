import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CartItem, CartProduct } from "../model/cart-item.model";
import { LocalSettingsService } from "./local-settings.service";
import { Item } from "../model/product.model";
import { SharedService } from "./shared.service";

@Injectable({
  providedIn: "root",
})
export class CartService {
  some(arg0: (item: any) => boolean) {
    throw new Error("Method not implemented.");
  }
  private _numberOfItems: number = 0;
  private _totalAmount: number = 0;
  private _cartItemList: CartProduct[] = [];
  private _numberOfItemsSubject$: BehaviorSubject<number> = new BehaviorSubject(
    0
  );
  private _cartTotalAmountSubject$: BehaviorSubject<number> =
    new BehaviorSubject(0);
  private _cartItemsSubject$: BehaviorSubject<Array<CartProduct>> =
    new BehaviorSubject(new Array<CartProduct>());
  numOfItemEvent = this._numberOfItemsSubject$.asObservable();
  cartItemsEvent = this._cartItemsSubject$.asObservable();
  totalAmountEvent = this._cartTotalAmountSubject$.asObservable();

  constructor(
    private setting: LocalSettingsService,
    private sharedService: SharedService
  ) {
    this._numberOfItems = this._numberOfItemsSubject$.getValue();
    this._cartItemList = this._cartItemsSubject$.getValue();
    this._totalAmount = this._cartTotalAmountSubject$.getValue();
  }

  addItem(item: CartProduct): boolean {
    //console.log(item)
    const cartItem = this._cartItemList.find(
      (items) =>
        items.item.item_id === item.item.item_id &&
        items.item.variants[0].variant_id ===
          item.item.variants[0].variant_id &&
        items.item.variants[0].stock[0].id === item.item.variants[0].stock[0].id
    );
    if (!cartItem) {
      const format = this.sharedService.calculatePrice(
        item.item.variants[0].stock[0].price,
        item.item.variants[0].discount_type,
        item.item.variants[0].discount_value,
        item.item.variants[0].enable_discount
      );
      const totalProducts = format.newPrice * item.qty;
      item.discount = format.discount * item.qty;
      item.price = totalProducts;
      this._cartItemList.push(item);
      this._numberOfItems += 1;
      //this._totalAmount += this.sharedService.toFixedNumber(totalProducts);
      this._cartItemsSubject$.next(this._cartItemList);
      this._numberOfItemsSubject$.next(this._numberOfItems);
      //this._cartTotalAmountSubject$.next(this._totalAmount);
      this._totalAmount = 0;
      for (const cart of this._cartItemList) {
        this._totalAmount += this.sharedService.toFixedNumber(cart.price);
      }
      this._cartTotalAmountSubject$.next(this._totalAmount);
      this.cartToStorage();
      return true;
    }
    return false;
  }
  removeAndUpdateTotalCartPrice(price: number): void {
    this._totalAmount = this.sharedService.toFixedNumber(price);
    this._cartTotalAmountSubject$.next(this._totalAmount);
    this.cartToStorage();
  }
  removeAndUpdateTotalCartlength(length: number): void {
    this._numberOfItems = length;
    this._numberOfItemsSubject$.next(this._numberOfItems);
    this.cartToStorage();
  }
  updateTotalPrice(price: number): void {
    this._totalAmount += this.sharedService.toFixedNumber(price);
    this._cartTotalAmountSubject$.next(this._totalAmount);
    this.cartToStorage();
  }
  removeItem(item: CartProduct): void {
    const itemToRemoveIndex = this._cartItemList.findIndex(function (items) {
      return (
        items.item.item_id === item.item.item_id &&
        items.item.variants[0].variant_id ===
          item.item.variants[0].variant_id &&
        items.item.variants[0].stock[0].id === item.item.variants[0].stock[0].id
      );
    });
    this._cartItemList.splice(itemToRemoveIndex, 1);
    this._cartItemsSubject$.next(this._cartItemList);
    this._numberOfItems -= 1;
    this._numberOfItemsSubject$.next(this._numberOfItems);
    //this._totalAmount -= this.sharedService.toFixedNumber((item.price * item.qty));
    //this._cartTotalAmountSubject$.next(this._totalAmount);
    this._totalAmount = 0;
    this._cartItemList.map((cart) => {
      //console.log(cart)
      this._totalAmount += this.sharedService.toFixedNumber(cart.price);
    });
    //console.log(this._totalAmount);
    this._cartTotalAmountSubject$.next(this._totalAmount);
    this.cartToStorage();
  }

  updateItemQty(item: Item, qty: number, price: number): void {
    if (qty === 0) {
      this.removeItem({
        item: item,
        qty: qty,
        price: price,
        originalPrice: 0,
        discount: 0,
      });
    } else {
      const cartItem = this._cartItemList.find((items) =>
          items.item.item_id === item.item_id &&
          items.item.variants[0].variant_id === item.variants[0].variant_id &&
          items.item.variants[0].stock[0].id === item.variants[0].stock[0].id
      );
      if (cartItem) {
        const format = this.sharedService.calculatePrice(
          item.variants[0].stock[0].price,
          item.variants[0].discount_type,
          item.variants[0].discount_value,
          item.variants[0].enable_discount
        );
        this._totalAmount -= this.sharedService.toFixedNumber(cartItem.price);
        cartItem.qty = qty;
        cartItem.discount = format.discount;
        const totalProducts = format.newPrice * qty;
        cartItem.price = totalProducts;
        //this._totalAmount = this.sharedService.toFixedNumber(
        //  this._totalAmount + totalProducts
        //);
        //this._cartTotalAmountSubject$.next(this._totalAmount);
        this._totalAmount = 0;
        this._cartItemList.map((cart) => {
          //console.log(cart)
          this._totalAmount += this.sharedService.toFixedNumber(cart.price);
        });
        //console.log(this._totalAmount);
        this._cartTotalAmountSubject$.next(this._totalAmount);
        this.cartToStorage();
      }
    }
  }
  resetCart(): void {
    this._numberOfItems = 0;
    this._cartItemList = [];
    this._totalAmount = 0;
    this._cartItemsSubject$.next(this._cartItemList);
    this._cartTotalAmountSubject$.next(this._totalAmount);
    this._numberOfItemsSubject$.next(this._numberOfItems);
    this.cartToStorage();
  }

  cartToStorage() {
    const cart = {
      _numberOfItems: this._numberOfItems,
      _cartItemList: this._cartItemList,
      _totalAmount: this._totalAmount,
    };
    this.setting.setItem("cart", cart);
  }
  storageToCart() {
    const cart = this.setting.getItem("cart");
    if (cart) {
      this._numberOfItems = cart._cartItemList.length;
      this._cartItemList = cart._cartItemList;
      this._totalAmount = cart._totalAmount;
      this._cartItemsSubject$.next(this._cartItemList);
      this._numberOfItemsSubject$.next(this._numberOfItems);
      this._cartTotalAmountSubject$.next(this._totalAmount);
    }
  }
  getLocalCart() {
    return this.setting.getItem("cart") || {};
  }
  findItemById(id: number): any {
    const foundItem = this._cartItemList.find((i) => i.item.item_id === id);
    // console.log("this._cartItemList", this._cartItemList, id, foundItem);

    if (foundItem) {
      return foundItem;
    } else {
      return null;
    }
  }


  findItemBySizeIdAndColorId(id: number, sizeId:any , colorId:any): any {
    const foundItem = this._cartItemList.find((i) => i.item.item_id === id  && i.item.variants[0].variant_id == sizeId.variant_id && i.item.variants[0].stock[0].color_id == colorId.color_id);
    if (foundItem) {
      return foundItem;
    } else {
      return null;
    }
  }



  // findItemByColorId(id: number, colorId: any): any {
  //   const foundItem = this._cartItemList.find((i) => {
  //     console.log(i, 'sssssssssssssssssssssize');

  //   });
  //   // console.log("this._cartItemList", this._cartItemList, id, foundItem);

  //   if (foundItem) {
  //     return foundItem;
  //   } else {
  //     return null;
  //   }
  // }
}
