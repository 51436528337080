import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";
import { NotFoundComponent } from "./page/not-found/not-found.component";

const routes: Routes = [
  {
    path: "home",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./page/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./page/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "delete/account",
    loadChildren: () =>
      import("./page/delete/delete.module").then((m) => m.DeleteModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./page/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "reset-password/:token",
    loadChildren: () =>
      import("./page/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./page/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "register",
    loadChildren: () =>
      import("./page/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "contactus",
    loadChildren: () =>
      import("./page/contactus/contactus.module").then(
        (m) => m.ContactusModule
      ),
  },
  {
    path: "aboutus",
    loadChildren: () =>
      import("./page/aboutus/aboutus.module").then((m) => m.AboutusModule),
  },
  {
    path: "order",
    loadChildren: () => import("./page/orders-replace/orders-replace.module").then((m) => m.OrdersReplaceModule),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./page/faq/faq.module").then((m) => m.FaqModule),
  },
  {
    path: "termsofuse",
    loadChildren: () =>
      import("./page/terms-of-use/terms-of-use.module").then(
        (m) => m.TermsOfUseModule
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./page/terms-of-use/terms-of-use.module").then(
        (m) => m.TermsOfUseModule
      ),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./page/checkout/checkout.module").then((m) => m.CheckoutModule),
  },
  {
    path: "checkout/success/:orderId",
    loadChildren: () =>
      import("./page/success-checkout/success-checkout.module").then(
        (m) => m.SuccessCheckoutModule
      ),
  },
  {
    path: "checkout/failed/:orderId",
    loadChildren: () =>
      import("./page/failed-checkout/failed-checkout.module").then(
        (m) => m.FailedCheckoutModule
      ),
  },
  {
    path: "cart",
    loadChildren: () =>
      import("./page/cart/cart.module").then((m) => m.CartModule),
  },
  {
    path: "brands",
    loadChildren: () =>
      import("./page/brands/brands.module").then((m) => m.BrandsModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./page/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "product/:prdouctid",
    loadChildren: () =>
      import("./page/product-info/product-info.module").then(
        (m) => m.ProductInfoModule
      ),
  },
  {
    path: "favorite",
    loadChildren: () =>
      import("./page/favorite/favorite.module").then((m) => m.FavoriteModule),
  },
  {
    path: "offers",
    loadChildren: () =>
      import("./page/offers/offers.module").then((m) => m.OffersModule),
  },
  {
    path: "services",
    loadChildren: () =>
      import("./page/our-services/our-services.module").then(
        (m) => m.OurServicesModule
      ),
  },
  // {
  //   path: "download",
  //   loadChildren: () =>
  //     import("./page/download/download.module").then(
  //       (m) => m.downloadModule
  
  //     ),
  // },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation: "enabled",
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
