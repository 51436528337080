import {Injectable} from "@angular/core";
import {Price} from "../model/cart-item.model";

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    constructor() { }
    toFixedNumber(someNumber: number | string) : number{
        const newVar = Number(someNumber)
        return +newVar.toFixed(3);
    }
    calculatePrice(price: string, discountType: string, discountValue: number, enableDiscount: number): { discount: number; newPrice: number } {
        if(enableDiscount === 1) {
            if(discountType == 'percent') {
            const newPrice = (this.toFixedNumber(price) - ((this.toFixedNumber(price) / 100) * discountValue))
            const discount = ((this.toFixedNumber(price) / 100) * discountValue)
            return {
                newPrice: this.toFixedNumber(newPrice),
                discount: this.toFixedNumber(discount)
            }
        }
        if(discountType == 'amount') {
            const newPrice = (this.toFixedNumber(price) - discountValue)
            return {
                newPrice: this.toFixedNumber(newPrice),
                discount: this.toFixedNumber(discountValue)
            }
        }
        if(discountType == 'value') {
            const newPrice = (this.toFixedNumber(price) - discountValue)
            return {
                newPrice: this.toFixedNumber(newPrice),
                discount: this.toFixedNumber(discountValue)
            }
        }
    const newPrice = (this.toFixedNumber(price))
    return {
        newPrice: this.toFixedNumber(newPrice),
        discount: 0
    }
    }
        const newPrice = (this.toFixedNumber(price))
        return {
            newPrice: this.toFixedNumber(newPrice),
            discount: 0
        }
    }
}