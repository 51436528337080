import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoaderService } from '../service/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _loader: LoaderService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._loader.visibility = true;
    const authToken = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') ?? '') : '';
    if (request.url.indexOf('/api/web') !== -1 && authToken) {
      request = request.clone({ headers: request.headers.set('Authorization', `bearer ${authToken.access_token}`) })
    }
    return next.handle(request).pipe(tap(e => {
      if(e instanceof HttpResponse) {
        this._loader.visibility = false;
      }
    }));
  }
}
