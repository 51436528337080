import { Injectable } from "@angular/core";
import { ContactInfo } from "../model/contact-info.model";

@Injectable({
  providedIn: "root",
})
export class ContactInfoService {
  constructor() {}

  getInfo = (): ContactInfo => ({
    mobile_number: "96597687419",
    telephone_number: "505050505",
    email_address: "info@petpointkw.com",
    address: "Street 26 block 1 Building 224, Aswaq Alqurain , Alkuwait",
    longtitude: "35.8825136",
    latitude: "31.8893227",
  });
}
