import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalSettingsService} from "../service/local-settings.service";

@Injectable()
export class MainInterceptor implements HttpInterceptor {

  constructor(private _localSettings: LocalSettingsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({ headers: request.headers.set('Language', localStorage.getItem('language') || "") });
    if (request.url.indexOf('/api/') !== -1) {
      const storedLang: string = this._localSettings.getLanguage();
      request = request.clone({
            setHeaders: {
              'Password': 'pass123456',
              'Language': storedLang,
            }
      });
    }
    return next.handle(request);
  }
}
