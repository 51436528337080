import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";

const APP_PREFIX: string = environment.Prefix;

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService {
  constructor() { }

  getLanguage(): string {
    if (localStorage) {
      return localStorage['language'] || "";
    }
    else {
      return "";
    }
  }
  setLanguage(language: string) {
    if (localStorage) {
      localStorage['language'] = language;
    }
  }
  setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }
  setWithExpiry(key: string, value: string) {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + 3600 * 1000,
    };
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(item));
  }
  getItem(key: string) {
    const itemStr = localStorage.getItem(`${APP_PREFIX}${key}`);
    if (itemStr) {
      return JSON.parse(itemStr);
    } else {
      return null
    }
  }
  getItemWithExpiry(key: string) {
    const itemStr = localStorage.getItem(`${APP_PREFIX}${key}`);
    if (!itemStr) {
      return [];
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(`${APP_PREFIX}${key}`);
      return [];
    }
    return item.value;
  }
  removeItem(key: string) {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }
}
