import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _visibleEvent: Subject<boolean> = new Subject();
  event = this._visibleEvent.asObservable();

  constructor() {
  }

  set visibility(value: boolean) {
    this._visibleEvent.next(value);
  }
}
