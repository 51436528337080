<section class="e404">
  <div class="container">
    <div class="page_4">
      <h3>404</h3>
      <h6>{{ "page_not_found" | translate }}</h6>
      <p>{{ "page_not_found_message" | translate }}</p>
      <a routerLink="/">{{ "homepage" | translate }}</a>
      <ul>
        <li>
          <a href="https://www.instagram.com/petpointkw/?hl=en" target="new"
            ><i class="fab fa-instagram"></i
          ></a>
        </li>
        <li>
          <a href="https://facebook.com" target="new"
            ><i class="fab fa-facebook-f"></i
          ></a>
        </li>
      </ul>
      <div class="text_404">
        <p><i class="fal fa-mobile"></i>{{ settings?.phone }}</p>
        <p><i class="fal fa-envelope"></i>{{ settings?.adminEmail }}</p>
      </div>
    </div>
  </div>
</section>
