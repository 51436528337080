import { Component, OnInit } from "@angular/core";
import { Settings } from "src/app/interface/settings.interface";
import { LocalSettingsService } from "src/app/service/local-settings.service";
import { SettingsService } from "src/app/service/settings.service";

@Component({
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
})
export class NotFoundComponent implements OnInit {
  settings: Settings | undefined;
  constructor(
    public _localSettings: LocalSettingsService,
    private settingsService: SettingsService
  ) {
    this.settings = this.settingsService._settings.getValue();
  }

  ngOnInit() {}
}
