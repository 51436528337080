import { Injectable }  from '@angular/core';
import {SettingsService} from "./settings.service";
import {map} from "rxjs";
import {Settings, Faq} from "../interface/settings.interface";

@Injectable()
export class AppInitService {

    constructor(private settingsService: SettingsService) {
    }

    PromiseGetSettings = new Promise<void>((resolve, reject) => {
        this.settingsService.getSettings()
            .pipe(map(resp => resp.result.success === 'success' && resp.result.data.setting))
            .subscribe((settings: Settings) => {
                resolve(this.settingsService.changeSettingsData(settings))
            })
    })

    PromiseGetFaqs = new Promise<void>((resolve, reject) => {
        this.settingsService.getFaqs()
            .pipe(map(resp => resp.result.success === 'success' && resp.result.data.faq))
            .subscribe((faqs: Faq[]) => {
                resolve(this.settingsService.changeFaqsData(faqs))
            })
    })

    Init() {
        return Promise.all([this.PromiseGetSettings, this.PromiseGetFaqs])
    }
}