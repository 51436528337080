export const environment = {
  production: true,
  api_url: "https://admin.petpointkw.com/api/web",
  api_url_mobile: "https://admin.petpointkw.com/api/mobile",
  auth_pass: "pass123456",
  img_host_url: "https://petpointkw.com/",
  Prefix: "PetPoint-",
  recaptcha: {
    siteKey: "6LckVaokAAAAAEH1IJunOcanu_qTQfnHJIe587Kp",
  },
  firebaseConfig: {
    apiKey: "AIzaSyBRRCq2oIQILePSK-9uzaJTZSS47OBS2L8",
    authDomain: "auth.petpointkw.com",
    databaseURL: "https://petshop-cf224.firebaseio.com",
    projectId: "petshop-cf224",
    storageBucket: "petshop-cf224.appspot.com",
    messagingSenderId: "1032273565893",
    appId: "1:1032273565893:web:c34c1dc7cb0c9b902bf8ac",
    measurementId: "G-1R840FYM5K",
  },
};
